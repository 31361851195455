#form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  margin: 25px auto 0;

  @media screen and (max-width: 799px) {
    width: 95vw;
  }

  .MuiInputBase-formControl {
    border-radius: 0;
    margin-bottom: 15px;
  }

  .Mui-focused fieldset {
    border-color: darken(#86c456, 10%);
  }

  label.Mui-focused {
    color: darken(#86c456, 10%);
  }

  .order-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .full-name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  #samples {
    width: 100%;

    .MuiInputBase-formControl {
      margin: 0 10px 0 0;

      @media screen and (max-width: 767px) {
        margin: 0 0 12px 0;
      }
    }

    .label {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0.75rem 0;

      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }

    .sample {
      margin-bottom: 15px;

      .fields {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        .type-select {
          min-width: 130px;

          @media screen and (max-width: 767px) {
            min-width: 223px;
          }
        }
      }

      .fake-cancel {
        width: 56px;
        height: 56px;

        @media screen and (max-width: 767px) {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .actions {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .button {
    width: 250px;
    color: #ffffff;
    font-family: "Futura PT", Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 0;
    padding: 15px 50px;
    margin: 25px 0 0;
    cursor: pointer;
    transition: all 0.2s ease-out;
  }

  #add-sample {
    background-color: #2d2d2d;
    margin-right: 2rem;

    @media screen and (max-width: 767px) {
      margin-right: unset;
    }

    &:hover {
      background-color: lighten(#2d2d2d, 5%);
    }
  }

  #submit {
    background-color: #86c456;

    &:hover {
      background-color: lighten(#86c456, 5%);
    }
  }
}

#close {
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #86c456;
  color: #ffffff;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: lighten(#86c456, 5%);
  }
}

#submit {
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #86c456;
  color: #ffffff;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: lighten(#86c456, 5%);
  }
}

#cancel {
  font-family: "Futura PT", Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #cacaca;
  color: #2d2d2d;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: darken(#cacaca, 5%);
  }
}
