@font-face {
  font-family: "Futura PT";
  src: url("fonts/FuturaPT-Book.eot") format("eot"), url("fonts/FuturaPT-Book.woff") format("woff"),
    url("fonts/FuturaPT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura PT";
  src: url("fonts/FuturaPT-Demi.eot") format("eot"), url("fonts/FuturaPT-Demi.woff") format("woff"),
    url("fonts/FuturaPT-Demi.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Futura PT", Arial, sans-serif;
  color: #2d2d2d;
  margin: 0;
}
