header {
  position: relative;
  height: 100px;
  width: 100%;

  img {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 160px;
    height: auto;
  }
}
