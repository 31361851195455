#title {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 40px;
    margin: 0 auto 20px;

    @media screen and (max-width: 767px) {
      margin: 20px auto;
    }
  }

  p {
    max-width: 750px;
    font-size: 20px;
    text-align: center;
    margin: 0 auto 10px;

    @media screen and (max-width: 767px) {
      max-width: 95vw;
    }
  }
}
